<template>
  <section class="seeusers__policy">
    <div class="seeusers__policy--container" v-for="city in cities" :key="city.id">
      <label class="seeusers__policy--container-city">
        <input type="checkbox" v-model="city.isChecked" :value="city.isChecked" :name="city.cityName" @change="toggleCheck(city)" />
        {{ city.cityName }}
      </label>
      <ul class="offices__container" v-if="city.offices.length">
        <li class="offices__container--office" v-for="office in city.offices" :key="office.id">
          <label>
            <input
              type="checkbox"
              v-model="office.isChecked"
              :value="office.isChecked"
              :name="office.officeName"
              @change="toggleCheck(city, office)"
            />
            {{ office.officeName }}
          </label>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: ["policy", "user"],
  data() {
    return {
      cities: [],
      previous: this.user.polices?.["see-models"]?.payload,
      hasAddedCity: false,
    };
  },
  computed: {
    citiesDb() {
      return this.$store.state.cities.data;
    },
    offices() {
      return this.$store.getters["offices/getData"];
    },
    buildResult() {
      const cityCoordinator = this.user.role === "coordinator" ? this.user.city : null;
      const hasPolice = !!this.previous;
      const allOptionsSeleted = this.cities.reduce((result, city) => {
        const seletedCities = city.offices.filter((office) => office.isChecked).map((office) => office.officeName);

        if (seletedCities.length) {
          result[city.cityName] = seletedCities;
          if (city.cityName === cityCoordinator) {
            this.hasAddedCity = true;
          }
        }
        return result;
      }, {});

      if (
        cityCoordinator &&
        !hasPolice &&
        !allOptionsSeleted.hasOwnProperty(cityCoordinator) &&
        !this.hasAddedCity &&
        Object.values(allOptionsSeleted).length
      ) {
        const offices = this.cities.find((city) => city.cityName === cityCoordinator)?.offices.map((office) => office.officeName) || [];
        allOptionsSeleted[cityCoordinator] = offices;
        this.hasAddedCity = true;
      }

      return { allOptionsSeleted };
    },
  },
  methods: {
    createCityOptions() {
      return this.citiesDb.map((city) => {
        const officeFilter = this.offices.filter((office) => office.cityName.toUpperCase() === city.cityName.toUpperCase());

        const offices = this.createOfficesOptions(officeFilter);

        const allOfficesChecked = offices.every((office) => office.isChecked);

        return {
          cityName: city.cityName,
          offices,
          isChecked: allOfficesChecked,
        };
      });
    },

    createOfficesOptions(officeFilter) {
      const polices = this.user.polices?.["see-models"]?.payload ?? {};

      return officeFilter.map((office) => {
        const isChecked = polices[office.cityName]?.includes(office.officeName) ?? false;

        return {
          cityName: office.cityName,
          officeName: office.officeName,
          isChecked,
        };
      });
    },

    toggleCheck(city, office) {
      const isChecked = office ? office.isChecked : city.isChecked;
      if (office) {
        office.isChecked = isChecked;
      } else {
        city.isChecked = isChecked;
        city.offices.forEach((office) => {
          office.isChecked = isChecked;
        });
      }

      const allOfficesState = city.offices.every((office) => office.isChecked);
      city.isChecked = allOfficesState;

      this.$emit("result-updated", { politic: this.buildResult });
    },
    refresOptions() {
      this.cities = this.createCityOptions();
    },
    getResult() {
      const { allOptionsSeleted = {} } = this.buildResult;
      const dataFlat = Object.values(allOptionsSeleted)
        .flat()
        .filter(Boolean);

      return !!dataFlat.length;
    },
  },

  mounted() {
    this.cities = this.createCityOptions();
  },
};
</script>

<style lang="scss">
.seeusers__policy {
  width: 100%;
  &--container {
    &-city {
      font-weight: bold;
    }
    .offices__container {
      @include Flex(row, flex-start, center);
      margin: 10px 0;
      padding-left: 15px;
      &--office {
        margin: 0 10px;
        list-style: none;
      }
    }
  }
}
</style>
